const FieldComponent = Formio.Components.components.field;

class DateSelectorComponent extends FieldComponent {

  static schema(...extend) {
    return FieldComponent.schema({
      label: 'Date Selector Component',
      type: 'DateSelector',
      key: 'DateSelector',
      calendarType: 'Gregorian',
      minYear: 1900,
      maxYear: 2100,
      dateOrder: 'day,month,year',
      dateFormat: 'DD-MM-YYYY'
    });
  }

  static get builderInfo() {
    return {
      title: 'Date Selector',
      icon: 'calendar',
      group: 'advanced',
      documentation: '/userguide/#date',
      weight: 0,
      schema: DateSelectorComponent.schema()
    };
  }

  constructor(component, options, data) {
    super(component, options, data);
    this.componentId = `dataSelector-${Math.random().toString(36).slice(2)}`;
    this.dayId = `day-${Math.random().toString(36).slice(2)}`;
    this.monthId = `month-${Math.random().toString(36).slice(2)}`;
    this.yearId = `year-${Math.random().toString(36).slice(2)}`;
  }

  createForm(container, fieldOrder, calendarType) {
    const formFragment = document.createDocumentFragment();
    const inputs = createInputs(this, calendarType);
    const inputGroups = createInputGroups(inputs);
    
    fieldOrder.forEach((field) => {
        if (inputGroups[field]) {
            formFragment.appendChild(inputGroups[field]);
        }
    });

    container.appendChild(formFragment);

    function createInputGroups(elements) {
        const groups = {};
        for (const key in elements) {
            groups[key] = createInputGroup(elements[key], key);
        }
        return groups;
    }

    function createInputGroup(element, key) {
        const group = document.createElement("div");
        group.className = "input-group";
        group.style = `display: flex; gap: 10px; max-width: ${key === 'month' ? '100%' : '80px'};`;
        group.appendChild(element);
        return group;
    }

    function createInputs(context, calendarType) {
        // Create the day, month, and year inputs
        const dayInput = document.createElement("input");
        dayInput.setAttribute('ref', 'dateSelectorDay');
        dayInput.type = "number";
        dayInput.id = context.dayId;
        dayInput.classList.add("input-with-feedback", "form-control", "bold");
        dayInput.name = "day";
        dayInput.placeholder = context.t("Day");
        // dayInput.style = "max-width: 80px;";
        dayInput.min = 1;
        dayInput.max = context.component.calendarType === 'Hijri' ? 30 :31;

        const monthSelect = document.createElement("select");
        monthSelect.setAttribute('ref', 'dateSelectorMonth');
        monthSelect.id = context.monthId;
        monthSelect.classList.add("input-with-feedback", "form-control", "ellipsis");
        monthSelect.style = "flex: 1;";

        if (calendarType === "Gregorian") {
          monthSelect.innerHTML = `
              <option value="" disabled selected hidden>${context.t('Month')}</option>
              <option value="1">${context.t('January')}</option>
              <option value="2">${context.t('February')}</option>
              <option value="3">${context.t('March')}</option>
              <option value="4">${context.t('April')}</option>
              <option value="5">${context.t('May')}</option>
              <option value="6">${context.t('June')}</option>
              <option value="7">${context.t('July')}</option>
              <option value="8">${context.t('August')}</option>
              <option value="9">${context.t('September')}</option>
              <option value="10">${context.t('October')}</option>
              <option value="11">${context.t('November')}</option>
              <option value="12">${context.t('December')}</option>
          `;
      } else if (calendarType === "Hijri") {
          monthSelect.innerHTML = `
              <option value="" disabled selected hidden>${context.t('Month')}</option>
              <option value="1">${context.t('Muharram')}</option>
              <option value="2">${context.t('Safar')}</option>
              <option value="3">${context.t("Rabi' al-awwal")}</option>
              <option value="4">${context.t("Rabi' al-thani")}</option>
              <option value="5">${context.t('Jumada al-awwal')}</option>
              <option value="6">${context.t('Jumada al-thani')}</option>
              <option value="7">${context.t('Rajab')}</option>
              <option value="8">${context.t("Sha'ban")}</option>
              <option value="9">${context.t('Ramadan')}</option>
              <option value="10">${context.t('Shawwal')}</option>
              <option value="11">${context.t("Dhu al-Qi'dah")}</option>
              <option value="12">${context.t("Dhu al-Hijjah")}</option>
          `;
      }

        const yearInput = document.createElement("input");
        yearInput.setAttribute('ref', 'dateSelectorYear');
        yearInput.type = "number";
        yearInput.id = context.yearId;
        yearInput.classList.add("input-with-feedback", "form-control", "bold");
        yearInput.name = "year";
        yearInput.placeholder = context.t("Year");
        yearInput.min = context.component.minYear;
        yearInput.max = context.component.maxYear;

        return { day: dayInput, month: monthSelect, year: yearInput };
    }
  }


  

  initDateSelector() {
  
    const formContainer = document.getElementById(this.componentId);
    const fieldOrder = this.component.dateOrder.split(',');
    this.createForm(formContainer, fieldOrder, this.component.calendarType);
  };
  

  attach(element) {
    this.initDateSelector();

    this.loadRefs(element, {
      dateSelectorDay: "single",
      dateSelectorMonth: "single",
      dateSelectorYear: "single",
    });

    this.addEventListener(this.refs.dateSelectorDay, "input", () => this.validateDay());
    this.addEventListener(this.refs.dateSelectorMonth, "change", () => this.validateMonthAndAdjustDay());
    this.addEventListener(this.refs.dateSelectorYear, "blur", () => this.validateYear());
    
    return super.attach(element);

  }

  render(content) {
    return super.render(`<div id='${this.componentId}' style="display: flex; gap: 10px;"></div>`)
  }

  // Validate day input field
  validateDay() {
    const day = parseInt(document.getElementById(this.dayId).value);
    const month = parseInt(document.getElementById(this.monthId).value);
    const year = parseInt(document.getElementById(this.yearId).value);

    this.setValue(this.formatDate(day, month, year, this.component.dateFormat));
  }

  validateMonthAndAdjustDay() {
    const day = parseInt(document.getElementById(this.dayId).value);
    const month = parseInt(document.getElementById(this.monthId).value);
    const year = parseInt(document.getElementById(this.yearId).value);

    this.setValue(this.formatDate(day, month, year, this.component.dateFormat));

  }

  getDaysInMonth(month, year) {
    const daysInMonth = {
      1: 31,
      2: this.isLeapYear(year) ? 29 : 28,
      3: 31,
      4: 30,
      5: 31,
      6: 30,
      7: 31,
      8: 31,
      9: 30,
      10: 31,
      11: 30,
      12: 31,
    };

    return daysInMonth[month] || 31;
  }


  validateYear() {
    const day = parseInt(document.getElementById(this.dayId).value);
    const month = parseInt(document.getElementById(this.monthId).value);
    const year = parseInt(document.getElementById(this.yearId).value);

    this.setValue(this.formatDate(day, month, year, this.component.dateFormat));
  }


  isLeapYear(year) {
    return (year % 4 === 0 && (year % 100 !== 0 || (year % 400 === 0)));
  }

  formatDate(day, month, year, format) {
    // Pad day and month with leading zeros if needed
    const paddedDay = String(day).padStart(2, '0');
    const paddedMonth = String(month).padStart(2, '0');
  
    // Generate formatted date based on the specified format
    switch (format) {
      case 'DD-MM-YYYY':
        return `${paddedDay}-${paddedMonth}-${year}`;
      case 'MM-DD-YYYY':
        return `${paddedMonth}-${paddedDay}-${year}`;
      case 'YYYY-MM-DD':
        return `${year}-${paddedMonth}-${paddedDay}`;
      case 'DD/MM/YYYY':
        return `${paddedDay}/${paddedMonth}/${year}`;
      case 'MM/DD/YYYY':
        return `${paddedMonth}/${paddedDay}/${year}`;
      case 'YYYY/MM/DD':
        return `${year}/${paddedMonth}/${paddedDay}`;
      default:
        throw new Error('Unsupported date format');
    }
  }

  isLeapYear(year) {
    return (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0));
  }
  
  getDaysInMonth(year, month) {
    const daysInMonth = [31, this.isLeapYear(year) ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    return daysInMonth[month - 1];
  }

  isValidGregorianDate(year, month, day, minYear, maxYear) {
    // Validate year within the range
    if (year < minYear || year > maxYear) {
      return false;
    }
  
    // Validate month is between 1 and 12
    if (month < 1 || month > 12) {
      return false;
    }
  
    // Validate day is within the valid range for the given month
    const maxDays = this.getDaysInMonth(year, month);
    if (day < 1 || day > maxDays) {
      return false;
    }
  
    // If all checks passed, the date is valid
    return true;
    
  }

  isValidHijriDate(year, month, day, minYear, maxYear) {
    try {
      if (year < minYear || year > maxYear) {
        return false;
      }

      if (day < 1 || day > 30) {
        return false;
      }

      return moment_hijri(`${year}/${month}/${day}`, 'iYYYY/iMM/iDD').isValid();
    } catch (e) {
      return true; // If an error occurs, the Hijri date is invalid
    }
  }

  checkValidity(data, dirty, rowData) {
    //Call the super checkValidity method to perform the default input validation.
    const isValid = super.checkValidity(data, dirty, rowData);

    //Call the customValidation method to perform the custom validation.
    const customValidationResult = this.customValidation();

    //If the custom validation failed, set the error message and return false.
    if (customValidationResult !== true) {
      this.setCustomValidity(customValidationResult);
      return false;
    }

    //If the custom validation passed, return the result of the default input validation.
    return isValid;
  }

  customValidation() {
    const day = parseInt(document.getElementById(this.dayId).value);
    const month = parseInt(document.getElementById(this.monthId).value);
    const year = parseInt(document.getElementById(this.yearId).value);

    if(this.component.calendarType === 'Hijri'){
      if (!this.isValidHijriDate(year, month, day, this.component.minYear, this.component.maxYear)) {
        return this.t('Invalid Hijri date provided. Please ensure the date falls within a valid range') + ` (${this.component.minYear} - ${this.component.maxYear}), ` + this.t('or enter a valid date.');
      }
    }
    else{
      if (!this.isValidGregorianDate(year, month, day, this.component.minYear, this.component.maxYear)) {
        return this.t('Invalid date provided. Please ensure the date falls within a valid range') + ` (${this.component.minYear} - ${this.component.maxYear}), ` + this.t('or enter a valid date.');
      }
    }

    return true;
  }
}


DateSelectorComponent.editForm = function () {
  return FieldComponent.editForm([
    {
      weight: 10,
      key: "config",
      label: "Config",
      components: [
        {
          weight: 500,
          type: "select",
          input: true,
          required: true,
          key: "calendarType",
          label: "Calendar Type",
          tooltip: "Choose between Gregorian and Hijri calendar types.",
          data: {
            values: [
              { label: "Gregorian", value: "Gregorian" },
              { label: "Hijri", value: "Hijri" },
            ],
            json: "",
            url: "",
            resource: "",
            custom: "",
          },
          dataSrc: "values",
          defaultValue: "Gregorian",
        },
        {
          weight: 510,
          type: "number",
          input: true,
          key: "minYear",
          label: "Minimum Year",
          tooltip: "Set the minimum year for the calendar.",
          defaultValue: 1900,
          validate: {
            integer: true,
            min: 1,
          },
          placeholder: "e.g., 1900",
        },
        {
          weight: 520,
          type: "number",
          input: true,
          key: "maxYear",
          label: "Maximum Year",
          tooltip: "Set the maximum year for the calendar.",
          defaultValue: 2100,
          validate: {
            integer: true,
            min: 2,
          },
          placeholder: "e.g., 2100",
        },
        {
          weight: 530,
          type: "select",
          input: true,
          key: "dateOrder",
          label: "Date Order",
          tooltip: "Select the order for day, month, year.",
          data: {
            values: [
              { label: "Day-Month-Year", value: "day,month,year" },
              { label: "Month-Day-Year", value: "month,day,year" },
              { label: "Year-Month-Day", value: "year,month,day" },
            ],
          },
          dataSrc: "values",
          defaultValue: "day,month,year",
        },
        {
          weight: 530,
          type: "select",
          input: true,
          key: "dateFormat",
          label: "Date Format",
          tooltip: "Select the format of the date.",
          data: {
            values: [
              { label: "DD-MM-YYYY", value: "DD-MM-YYYY" },
              { label: "MM-DD-YYYY", value: "MM-DD-YYYY" },
              { label: "YYYY-MM-DD", value: "YYYY-MM-DD" },
              { label: "DD/MM/YYYY", value: "DD/MM/YYYY" },
              { label: "MM/DD/YYYY", value: "MM/DD/YYYY" },
              { label: "YYYY/MM/DD", value: "YYYY/MM/DD" },
            ],
          },
          dataSrc: "values",
          defaultValue: "DD-MM-YYYY",
        },
      ],
    },
  ]);
};

Formio.use({
  components: {
    DateSelector: DateSelectorComponent
  }
});
